<template>
  <sign-page
    title-text="职称聘任排队情况"
    :request="request"
    :column-list="columnList"
    table-size="large"
    :tableActionsFixed="true"
    :titleMenus="[]"
    @tableAction="tableAction"
    :tableActions="tableActions"
    :need-data-file="true">
    <fm-modal v-model="modal" width="80%" title="排队明细">
      <div style="min-height:20rem" v-if="modal">
        <fm-table-new
          @on-column-config-update="(columns) => $onTableColumnUpdate(columns, 'details')"
          border="row"
          :toolbox="showToolBox"
          export-file-name="岗位排队情况"
          :simple-filter="true"
          size="small"
          :stripe="false"
          emptyPlaceholder="-"
          :columns="columnsW"
          :data-list="showData"
        ></fm-table-new>
      </div>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  jobTitleRequest as request
} from '@/api'

import {
  dealQueryData
} from '@/syslib/worker_job_title'

export default {
  methods: {
    tableAction (parm) {
      this.chooseData = parm.data
      this.modal = true
    }
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    tableActions () {
      return [{
        'key': 'queue',
        label: '排队明细',
        show: (data) => {
          return data.workers.length > 0
        }
      }]
    },
    showData () {
      return this.chooseData ? this.chooseData.workers : []
    },
    baseColumnsW () {
      return [{
        title: '序号',
        dataType: Number,
        sort: true,
        fixed: 'left',
        field: 'index'
      },
      {
        title: '员工编码',
        fixed: 'left',
        sort: true,
        field: 'code'
      },
      {
        title: '员工姓名',
        fixed: 'left',
        sort: true,
        field: 'name'
      },
      {
        title: '适岗执业资格证',
        sort: true,
        field: 'sgCertificateTitle'
      },
      {
        title: '适岗执业资格证获得时间',
        dataType: Date,
        sort: true,
        field: 'sgGetDate',
        render: (h, rowData) => {
          return h('div', rowData.sgGetDate ? rowData.sgGetDate.slice(0, 10) : '-')
        }
      },
      {
        title: '本岗累积工作年限',
        sort: true,
        dataType: Number,
        field: 'cJobTitleDiffYears'
      },
      {
        title: '当前岗位',
        sort: true,
        field: 'cJobTitleTitles'
      },
      {
        title: '当前岗位聘任起始时间',
        dataType: Date,
        sort: true,
        field: 'cJobTitleStartDates'
      },
      {
        title: '参加工作时间',
        dataType: Date,
        sort: true,
        field: 'correctionDate',
        render: (h, rowData) => {
          return h('div', rowData.correctionDate ? rowData.correctionDate.slice(0, 10) : '-')
        }
      },
      {
        title: '入职单位时间',
        dataType: Date,
        sort: true,
        field: 'entryDate',
        render: (h, rowData) => {
          return h('div', rowData.entryDate ? rowData.entryDate.slice(0, 10) : '-')
        }
      }]
    },
    columnsW () {
      return this.$getTableConfig('details', this.baseColumnsW)
    },
    columnList: {
      get () {
        let data = [{
          title: '岗位类型',
          sort: true,
          field: 'type'
        },
        {
          title: '岗位层级',
          sort: true,
          field: 'topLevel'
        },
        {
          title: '职称级别',
          sort: true,
          field: 'mLevel'
        },
        {
          title: '岗位等级',
          sort: true,
          field: 'title'
        },
        // {
        //   title: '需要资格证书',
        //   field: 'needCertificateTitle'
        // },
        // {
        //   title: '需要职称',
        //   field: 'needJobTitleTitle'
        // },
        // {
        //   title: '需要职称年限',
        //   field: 'needYear'
        // },
        {
          title: '实际可用岗位数',
          sort: true,
          field: 'num',
          dataType: Number
        },
        {
          title: '岗位实际人数',
          sort: true,
          field: 'workerJobTitleNum',
          dataType: Number
        },
        {
          title: '岗位空置数',
          sort: true,
          field: 'lastNum',
          dataType: Number,
          render: (h, rowData) => {
            return h('div', isNaN(rowData.lastNum) ? '-' : (rowData.lastNum < 0 ? 0 : rowData.lastNum))
          }
        },
        {
          title: '排队人数',
          sort: true,
          field: 'queueLength',
          dataType: Number
        },
        {
          title: '提醒',
          render: (h, rowData) => {
            return h('div', rowData.lastNum > 0 && rowData.queueLength > 0 ? '请尽快安排岗位调整' : '')
          },
          export (rowData) {
            return rowData.lastNum > 0 && rowData.queueLength > 0 ? '请尽快安排岗位调整' : ''
          },
        }]
        return data
      }
    }
  },
  data () {
    return {
      request: {
        get: async () => {
          let data = await request.getQueue()
          return dealQueryData(data)
        }
      },
      modal: false,
      chooseData: null
    }
  }
}
</script>
