export const dealQueryData = (data) => {
  data.forEach((v) => {
    v.workers = v.queueWorkers
    v.queueLength = v.queueWorkers.length
    let i = 0
    while (i < v.workers.length) {
      let w = v.workers[i]
      w.certificate = w.fitCertificateProQua
      w.sgCertificateTitle = w.certificate ? w.certificate.certificateTitle : ''
      w.sgGetDate = w.certificate ? w.certificate.getDate : ''
      w.cJobTitles = w.fitJobTitles || []
      w.cJobTitles = w.cJobTitles.filter(v => v.hireEndDate === null)
      w.cJobTitleDiffYears = w.cJobTitles.map(v2 => v2.diffYear || '无').join(',')
      w.cJobTitleTitles = w.cJobTitles.map(v2 => v2.jobTitleTitle || '无').join(',')
      w.cJobTitleStartDates = w.cJobTitles.map(v2 => v2.hireStartDate ? v2.hireStartDate.slice(0, 10) : '无').join(',')
      w.num1 = w.sgGetDate ? new Date(w.sgGetDate).getTime() : 0
      w.num2 = w.correctionDate ? new Date(w.correctionDate).getTime() : 0
      w.num3 = w.entryDate ? new Date(w.entryDate).getTime() : 0
      v.workers[i] = w
      i += 1
    }
    v.workers.forEach((v2, index) => v2.index = index + 1)
  })
  return data
}